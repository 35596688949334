import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import * as SettingActions from '../settings/settings.actions';

@Injectable()
export class SettingsEffects {

    constructor(
        private actions$: Actions
    ) { }

    togleDarkmode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingActions.ToggleDarkmode),
      tap(action => {
        return document.body.classList.toggle('dark');
      })
    ),
    { dispatch: false }
  );
}
