import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { props, Store } from '@ngrx/store';
import { first, takeUntil } from 'rxjs/operators';
import { Campaign } from 'src/app/classes/campaign/campaign';
import { AppState } from 'src/app/ngrx';
import * as fromSessionSelectors from 'src/app/ngrx/session/session.selectors';
import * as SessionActions from 'src/app/ngrx/session/session.actions';
import { IonSelect, PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { User } from 'src/app/classes/user/user';

@Component({
  selector: 'app-main-filter',
  templateUrl: './main-filter.component.html',
  styleUrls: ['./main-filter.component.scss'],
})
export class MainFilterComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line: variable-name
  public user: User;
  // tslint:disable-next-line: variable-name
  public _campaigns: Campaign[];
  // tslint:disable-next-line: variable-name
  public _campaignfilter: Array<string>;
  public _unclaimed: boolean;
  public _searchBarVisable: boolean;
  public _needsAttention: boolean;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @ViewChild('filterSelect') filterSelect: IonSelect;
  

  constructor(
    private store: Store<AppState>,
    private popoverController: PopoverController,
  ) {

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.store.select(fromSessionSelectors.SelectCampaigns).pipe(first()).subscribe(campaigns => {
      this._campaigns = campaigns;
    });
    this.store.select(fromSessionSelectors.SelectCampaignFilter).pipe(takeUntil(this.ngUnsubscribe)).subscribe(filter => {
      this._campaignfilter = filter;
    });
    this.store.select(fromSessionSelectors.SelectSearchBarVisible).pipe(takeUntil(this.ngUnsubscribe)).subscribe(v => {
      this._searchBarVisable = v;
    });
    this.store.select(fromSessionSelectors.SelectUnclaimedFilter).pipe(takeUntil(this.ngUnsubscribe)).subscribe(u => {
      this._unclaimed = u;
    });
    this.store.select(fromSessionSelectors.SelectAttentionFilter).pipe(takeUntil(this.ngUnsubscribe)).subscribe(n => {
      this._needsAttention = n;
    });

    this.store.dispatch(SessionActions.GetCampaigns());
    this.store.select(fromSessionSelectors.SelectCurrentUser).pipe(takeUntil(this.ngUnsubscribe)).subscribe(u => this.user = u);
  }

  applyFilter = () => {
    this.store.dispatch(SessionActions.UpdateCampaignFilter({ filter: this.filterSelect.value }));
    this.store.dispatch(SessionActions.UpdateSearchBarVisible({ newValue: this._searchBarVisable }));
    this.store.dispatch(SessionActions.UpdateUnclaimedFilter({ newValue: this._unclaimed }));
    this.store.dispatch(SessionActions.UpdateAttentionFilter({ newValue: this._needsAttention }));

    this.popoverController.dismiss();

  }

  clearFilter = () => {
    this.store.dispatch(SessionActions.UpdateCampaignFilter({ filter: [] }));
    this.store.dispatch(SessionActions.UpdateSearchBarVisible({ newValue: false }));
    this.store.dispatch(SessionActions.UpdateUnclaimedFilter({ newValue: false }));
    this.store.dispatch(SessionActions.UpdateAttentionFilter({ newValue: false }));

    this.popoverController.dismiss();
  }

  hideUncliamed = () => {
    return Number(this.user.user_level) !== 0;
  }
}
