<ion-menu side="start" contentId="main-content" menuId="main-menu" type="push">
<ion-content>
  <ion-list>
    <ion-menu-toggle auto-hide="true">
      <ion-item-divider>
        <ion-label> {{ user?.first_name }} {{ user?.last_name }} </ion-label>
        <ion-button
          color="medium"
          slot="end"
          fill="clear"
          routerDirection="forward"
          routerLink="/app/user"
        >
          <ion-icon
            name="settings-outline"
            slot="icon-only"
            size="small"
          ></ion-icon>
        </ion-button>
      </ion-item-divider>
      <ion-item
        routerDirection="forward"
        routerLink="/app/dashboard"
        routerLinkActive="active-item"
        lines="none"
      >
        <ion-icon slot="start" name="speedometer-outline"></ion-icon>
        <ion-label> Dashboard </ion-label>
      </ion-item>
      <ion-item
        routerDirection="forward"
        routerLink="/app/chat"
        routerLinkActive="active-item"
        lines="none"
      >
        <ion-icon slot="start" name="chatbubbles-outline"></ion-icon>
        <ion-label> Live Chat </ion-label>
      </ion-item>
      <ion-item
        routerDirection="forward"
        routerLink="/app/leads"
        routerLinkActive="active-item"
        lines="none"
      >
        <ion-icon slot="start" name="people-outline"></ion-icon>
        <ion-label> Leads </ion-label>
        <ion-note slot="end" color="primary">{{ leadcount }}</ion-note>
      </ion-item>
      <ion-item
        routerDirection="forward"
        routerLink="/app/pipeline"
        routerLinkActive="active-item"
        lines="none"
      >
        <ion-icon slot="start" name="funnel-outline"></ion-icon>
        <ion-label> My Pipeline </ion-label>
        <ion-note slot="end" color="primary">{{ pipelinecount }}</ion-note>
      </ion-item>
      <ion-item
        routerDirection="forward"
        routerLink="/app/calendar"
        routerLinkActive="active-item"
        lines="none"
      >
        <ion-icon slot="start" name="calendar-outline"></ion-icon>
        <ion-label> Calendar </ion-label>
      </ion-item>
    </ion-menu-toggle>
    @if(user?.user_level == 0){
    <ion-item-divider
      lines="none"
      (click)="admin === 1 ? (admin = 0) : (admin = 1)"
    >
      <ion-label> Admin </ion-label>
      <ion-button color="medium" slot="end" fill="clear">
        <ion-icon
          [name]="admin ? 'chevron-down-outline' : 'chevron-forward'"
          slot="icon-only"
          size="small"
        >
        </ion-icon>
      </ion-button>
    </ion-item-divider>
    }
    <ion-menu-toggle>
      @if(admin || user?.user_level == 0 || false){
      <ion-item
        routerDirection="forward"
        routerLink="/admin/settings"
        routerLinkActive="active-item"
        lines="none"
      >
        <ion-icon slot="start" name="construct-outline" size="small"></ion-icon>
        <ion-label> Settings </ion-label>
      </ion-item>
      } @if(admin || user?.user_level == 0 || false){
      <ion-item
        routerDirection="forward"
        routerLink="/admin/users"
        routerLinkActive="active-item"
        lines="none"
      >
        <ion-icon slot="start" name="person-outline" size="small"></ion-icon>
        <ion-label> Users </ion-label>
      </ion-item>
      } @if(admin || user?.user_level == 0 || false){
      <ion-item
        routerDirection="forward"
        routerLink="/admin/campaigns"
        routerLinkActive="active-item"
        lines="none"
      >
        <ion-icon slot="start" name="layers-outline" size="small"></ion-icon>
        <ion-label> Campaigns </ion-label>
      </ion-item>
      } @if(admin || user?.user_level == 0 || false){
      <ion-item
        routerDirection="forward"
        routerLink="/admin/huntgroups"
        routerLinkActive="active-item"
        lines="none"
      >
        <ion-icon slot="start" name="locate-outline" size="small"></ion-icon>
        <ion-label> Huntgroups </ion-label>
      </ion-item>
      }
    </ion-menu-toggle>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-text-center">
    <ion-button color="medium" expand="full" (click)="this.logout()">
      Log out
      <ion-icon name="log-out-outline" slot="end"></ion-icon>
    </ion-button>
    <ion-title><img src="./../../../assets/logo.svg" width="150" /></ion-title>
  </ion-toolbar>
</ion-footer>
</ion-menu>