import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestResponse } from 'src/app/classes/rest-response/rest-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HuntgroupsService {

  constructor(private httpClient: HttpClient) { }

  public getHuntgroups(): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(environment.apiUrl + '/rest/huntgroups', { withCredentials: true });
  }
}
