import { AdminGuard } from "src/app/guards/admin.guard";
import { NgModule } from "@angular/core";
import { NoPreloading, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  {
    path: "app",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/secure-routing/secure-routing.module").then(
        (m) => m.SecureRoutingModule
      ),
  },
  {
    path: "admin",
    canActivate: [AdminGuard],
    loadChildren: () =>
      import("./modules/admin-routing/admin-routing.module").then(
        (m) => m.AdminRoutingModule
      ),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./pages/privacy/privacy.module").then((m) => m.PrivacyPageModule),
  },
  {
    path: "terms-of-service",
    loadChildren: () =>
      import("./pages/terms/terms.module").then((m) => m.TermsPageModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },  {
    path: 'user-detail',
    loadChildren: () => import('./pages/users/user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
