import { R3Identifiers } from '@angular/compiler';

export class User {
    id: string;
    email: string;
    // tslint:disable-next-line:variable-name
    first_name: string;
    // tslint:disable-next-line:variable-name
    last_name: string;
    // tslint:disable-next-line:variable-name
    user_level: number;
    // tslint:disable-next-line:variable-name
    date_created: string;
    // tslint:disable-next-line:variable-name
    date_edited: string;

    photo: string;
    photo_type: string;
    experience: string;
    clients: string;
    bio: string;
    lat: number;
    lng: number;
    city: string;
    zipcode: number

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export interface UserCredentials {
    email: string;
    password: string;
}
