<ion-app>  
  @if(_pagedata?.title){
  <ion-header >
    <ion-toolbar color="light">
      <ion-buttons slot="start">
        @if(!_pagedata?.displayback){
          <ion-menu-button color="primary" menu="start">
          </ion-menu-button>
        }
        @if(_pagedata?.displayback){
          <ion-back-button color="primary" [defaultHref]="_pagedata?.backUrl">
          </ion-back-button>
        }
      </ion-buttons>
      <ion-title class="ion-text-capitalize" color="primary">
        {{_pagedata?.title}}
      </ion-title>
      @if(hideAttention()){
        <ion-buttons slot="end" >
          <ion-button (click)="linkToAttention($event)" color="danger">
            <ion-icon slot="end" name="notifications-outline" class="wiggle" size="large"></ion-icon>
            <ion-label color="medium">{{attLead + attPipe + attCal}}</ion-label>
          </ion-button>
        </ion-buttons>
      }
      @if(hidefilter()){
        <ion-buttons slot="end" >
          <ion-button (click)="showfilterpopover($event)">
            <ion-icon slot="icon-only" name="filter-outline" color="medium"></ion-icon>
          </ion-button>
        </ion-buttons>
      }
      @if(hidecalendarmode()){
        <ion-buttons slot="end" >
          <ion-button (click)="showcalendarpopover($event)">
            <ion-icon slot="icon-only" name="filter-outline" color="medium"></ion-icon>
          </ion-button>
        </ion-buttons>
      }
    </ion-toolbar>
  </ion-header>
}      
<ion-content [scrollY]="false" class="ion-no-padding">
        <main-menu></main-menu>
      <ion-router-outlet id="main-content">
      </ion-router-outlet>
  </ion-content>
  <ion-footer>
  </ion-footer>
</ion-app>