export class Campaign {
    id: string;
    // tslint:disable-next-line:variable-name
    date_created: string;
    name: string;
    description: string;
    // tslint:disable-next-line:variable-name
    date_edited: string;
    // tslint:disable-next-line:variable-name
    account_id: string;
    textintrotemplate: string;
    // tslint:disable-next-line:variable-name
    huntgroup_contactnumber_id: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }


}
