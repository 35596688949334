export class History {
  id: string;
  // tslint:disable-next-line:variable-name
  date_created: string;
  direction: string;
  type: string;
  detail: string;
  user_name: string;
  status: string;
  recordingurl: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
