<ion-list lines="none">
  <ion-radio-group #calendarRadioGroup [value]="_calendarmode" (ionChange)="changeCalendarView()">
    <ion-list-header>
      <ion-label>Calendar View</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-label>Day</ion-label>
      <ion-radio slot="start" value="day"></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label>Week</ion-label>
      <ion-radio slot="start" value="week"></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label>Month</ion-label>
      <ion-radio slot="start" value="month"></ion-radio>
    </ion-item>
  </ion-radio-group>
  <ion-button fill="clear" color="primary" expand="full" (click)="backtoToday()">
    <ion-icon name="today-outline" slot="end"></ion-icon>
    Today
  </ion-button>
</ion-list>