import { CampaignsService } from './../../services/data/campaigns/campaigns.service';
import { HuntgroupsService } from './../../services/data/huntgroups/huntgroups.service';
import { Chat } from './../../classes/chat/chat';
import { ChatService } from './../../services/data/chats/chat.service';
import { History } from './../../classes/conversation/history';
import { ConversationsService } from './../../services/data/conversations/conversations.service';
import { ContactNumber, ContactEmail, ContactAddress } from './../../classes/lead/lead';
import { Conversation } from './../../classes/conversation/conversation';
import { ListData } from './../../classes/list-data/list-data';
import { StatsService } from './../../services/data/stats/stats.service';
import { Storage } from '@ionic/storage';
import { UsersService } from './../../services/data/users/users.service';
import { NotificationsService } from './../../services/notifications.service';
import { NavController, ToastController } from '@ionic/angular';
import { User } from '../../classes/user/user';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, timer, config, interval } from 'rxjs';
import { catchError, map, tap, first, switchMap, audit, mergeMap, throttle } from 'rxjs/operators';
import * as SessionActions from './session.actions';
import { AuthenticationService } from '../../services/authentication.service';
import { LeadsService } from 'src/app/services/data/leads/leads.service';
import { Stats } from 'src/app/classes/stats/stats';
import { Lead } from 'src/app/classes/lead/lead';
import { MessagesService } from 'src/app/services/data/messages/messages.service';
import { Message } from 'src/app/classes/message/message';
import { Campaign } from 'src/app/classes/campaign/campaign';
import { Huntgroup } from 'src/app/classes/hungroup/huntgroup';
import { ScheduleService } from 'src/app/services/data/schedules/schedule.service';
import { ScheduleEvent } from 'src/app/classes/schedule/schedule-event';
import { ChatMessage } from 'src/app/classes/chat/chat-message';

@Injectable()
export class SessionEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private statsService: StatsService,
    private leadService: LeadsService,
    private chatService: ChatService,
    private campaignsService: CampaignsService,
    private huntgroupsService: HuntgroupsService,
    private navCtrl: NavController,
    public toastController: ToastController,
    private notificationsService: NotificationsService,
    private userService: UsersService,
    private conversationsService: ConversationsService,
    private messageService: MessagesService,
    private scheduleService: ScheduleService,
    private storage: Storage
  ) { storage.create();}

  apiMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.ApiMessage),
      throttle(() => timer(3000)),
      tap(action => {
        if (action.message !== 'pong') {
          this.toastController.create({
            message: action.message,
            duration: 1200
          }).then(t => t.present());
        }
        return;
      })
    ),
    { dispatch: false }
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.AuthUser),
      switchMap(action =>
        this.authService.login(action.creds).pipe(
          map(res => {
            if (res.result) {

              return SessionActions.AuthUserSuccess({ user: new User(res.data) });
            } else {
              return SessionActions.AuthUserFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.AuthUserFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  authPing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.AuthPing),
      switchMap(action =>
        this.authService.ping().pipe(
          map(res => SessionActions.AuthPingSuccess()),
          catchError(error => of(SessionActions.LogoutUserFail({ message: error })))
        )
      )
    )
  );

  logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.LogoutUser),
      switchMap(action =>
        this.authService.logout().pipe(
          map(res => SessionActions.LogoutUserSuccess()),
          catchError(error => of(SessionActions.LogoutUserFail({ message: error })))
        )
      )
    )
  );

  authError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.LogoutUserFail, SessionActions.AuthUserFail),
      tap(action => {
        this.toastController.create({
          message: action.message,
          duration: 2000
        }).then(t => t.present());
        // return this.navCtrl.navigateRoot(['login']);
      })
    ),
    { dispatch: false }
  );

  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.AuthUserSuccess),
      tap(action => {
        return this.navCtrl.navigateRoot(['app', 'dashboard']).then(() => {
          // tslint:disable-next-line:variable-name
          this.storage.get('devicetoken').then((_token: string) => {
            if (_token === null || _token === '') {
              this.notificationsService.requestPermission().then((token) => {
                this.storage.set('devicetoken', token);
                this.userService.registerNotificationToken(action.user, token, null).subscribe();
              });
            }
          });

        });
      })
    ),
    { dispatch: false }
  );

  unsetUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.LogoutUserSuccess),
      tap(action => {
        return this.navCtrl.navigateRoot(['login']);
      })
    ),
    { dispatch: false }
  );

  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.GetUsers),
      switchMap(action =>
        this.userService.getUsers().pipe(
          map(res => {
            if (res.result) {
              const users = new Array<User>();
              const listdata = new ListData(res.data);

              // tslint:disable-next-line:forin
              for (const key in listdata.list) {
                users.push(new User(listdata.list[key]));
              }

              return SessionActions.GetUsersSuccess({ users });
            } else {
              return SessionActions.GetUsersFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.GetUsersFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.UpdateUser),
      switchMap(action =>
        this.userService.UpdateUser(action.user, action.zipcode, action.experience, action.clients, action.bio).pipe(
          map(res => {
            if (res.result) {
              const user = new User (res.data[0]);
              return SessionActions.UpdateUserSuccess({ user });
            } else {
              return SessionActions.UpdateUserFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.UpdateUserFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  updateUserPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.UpdateUserPhoto),
      switchMap(action =>
        this.userService.UpdateUserPhoto(action.user, action.photo).pipe(
          map(res => {
            if (res.result) {
              return SessionActions.UpdateUserPhotoSuccess({  b64_photo: res.data.toString()  });
            } else {
              return SessionActions.UpdateUserPhotoFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.UpdateUserPhotoFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  getCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.GetCampaigns),
      switchMap(action =>
        this.campaignsService.getCampaigns().pipe(
          map(res => {
            if (res.result) {
              const campaigns = new Array<Campaign>();
              const listdata = new ListData(res.data);

              // tslint:disable-next-line:forin
              for (const key in listdata.list) {
                campaigns.push(new Campaign(listdata.list[key]));
              }

              return SessionActions.GetCampaignsSuccess({ campaigns });
            } else {
              return SessionActions.GetCampaignsFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.GetCampaignsFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  getHuntgroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.GetHuntgroups),
      switchMap(action =>
        this.huntgroupsService.getHuntgroups().pipe(
          map(res => {
            if (res.result) {
              const huntgroups = new Array<Huntgroup>();
              const listdata = new ListData(res.data);

              // tslint:disable-next-line:forin
              for (const key in listdata.list) {
                huntgroups.push(new Huntgroup(listdata.list[key]));
              }

              return SessionActions.GetHuntgroupsSuccess({ huntgroups });
            } else {
              return SessionActions.GetHuntgroupsFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.GetUsersFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  getStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.GetStats),
      switchMap(action =>
        this.statsService.getConversationStats().pipe(
          map(res => {
            if (res.result) {
              return SessionActions.GetStatsSuccess({ stats: new Stats(res.data) });
            } else {
              return SessionActions.GetStatsFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.GetStatsFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  getChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.GetChats),
      switchMap(action =>
        this.chatService.getChats().pipe(
          map(res => {
            if (res.result) {
              const chats = new Array<Chat>();
              const listdata = new ListData(res.data);

              // tslint:disable-next-line:forin
              for (const key in listdata.list) {
                chats.push(new Chat(listdata.list[key]));
              }

              return SessionActions.GetChatsSuccess({ chats });
            } else {
              return SessionActions.GetChatsFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.GetChatsFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  getLeads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.GetLeads),
      switchMap(action =>
        this.leadService.getActiveLeads().pipe(
          map(res => {
            if (res.result) {
              const leads = new Array<Lead>();
              const listdata = new ListData(res.data);

              // tslint:disable-next-line:forin
              for (const key in listdata.list) {
                leads.push(new Lead(listdata.list[key]));
              }

              return SessionActions.GetLeadsSuccess({ leads });
            } else {
              return SessionActions.GetLeadsFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.GetLeadsFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  getPipeline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.GetPipeline),
      switchMap(action =>
        this.leadService.getPipelineLeads().pipe(
          map(res => {
            if (res.result) {
              const leads = new Array<Lead>();
              const listdata = new ListData(res.data);

              // tslint:disable-next-line:forin
              for (const key in listdata.list) {
                leads.push(new Lead(listdata.list[key]));
              }

              return SessionActions.GetPipelineSuccess({ leads });
            } else {
              return SessionActions.GetPipelineFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.GetPipelineFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  getSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.GetSchedule),
      switchMap(action =>
        this.scheduleService.getSecheduledEvents().pipe(
          map(res => {
            if (res.result) {
              const events = new Array<ScheduleEvent>();
              const listdata = new ListData(res.data);

              // tslint:disable-next-line:forin
              for (const key in listdata.list) {
                events.push(new ScheduleEvent(listdata.list[key]));
              }

              return SessionActions.GetScheduleSuccess({ schedule: events });
            } else {
              return SessionActions.GetScheduleFail({ message: res.message });
            }
          }),
          catchError(error => of(SessionActions.GetScheduleFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  createLead$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SessionActions.CreateLead),
    switchMap(action =>
      this.campaignsService.createLead(action.campaign, action.lead).pipe(
        map(res => {
          if (res.result) {
            return SessionActions.CreateLeadSuccess({lead: new Lead(res.data[0])});
          } else {
            return SessionActions.CreateLeadFail({ message: res.message });
          }
        }
      )
    )
  ))
);

  updateLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.UpdateLead),
      switchMap(action =>
        this.leadService.updateLead(action.lead).pipe(
          map(res => {
            if (res.result) {
              return true;
            } else {
              return SessionActions.UpdateLeadFail({ message: res.message });
            }
          }),
          mergeMap(res => this.leadService.setPrimaryPhone(action.lead, action.phone.phonenumber).pipe(
            map(resp => {
              if (resp.result) {
                return true;
              } else {
                return SessionActions.UpdateLeadFail({ message: resp.message });
              }
            }),
            catchError(error => of(SessionActions.GetLeadsFail({ message: error })))
          )
          ),
          mergeMap(res => this.leadService.setPrimaryEmail(action.lead, action.email.email).pipe(
            map(rese => {
              if (rese.result) {
                return SessionActions.UpdateLeadSuccess();
              } else {
                return SessionActions.UpdateLeadFail({ message: rese.message });
              }
            }),
            catchError(error => of(SessionActions.GetLeadsFail({ message: error })))
          )
          ),
          mergeMap(res => this.leadService.setPrimaryAddress(action.lead, action.address).pipe(
            map(rese => {
              if (rese.result) {
                return SessionActions.UpdateLeadSuccess();
              } else {
                return SessionActions.UpdateLeadFail({ message: rese.message });
              }
            }),
            catchError(error => of(SessionActions.GetLeadsFail({ message: error })))
          )
          )
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  updateLeadSchedule$ = createEffect(() =>
    this.actions$.pipe(throttle(val => interval(2000)),
      ofType(SessionActions.UpdateLeadSchedule),
      switchMap(action =>
        this.leadService.updateLeadSchedule(action.lead, action.date).pipe(
          map(res => {
            if (res.result) {
              return SessionActions.UpdateLeadScheduleSuccess();
            } else {
              return SessionActions.UpdateLeadScheduleFail({ message: res.message });
            }
          })
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  updateLeadCloseProbability$ = createEffect(() =>
    this.actions$.pipe(throttle(val => interval(2000)),
      ofType(SessionActions.UpdateLeadCloseProbability),
      switchMap(action =>
        this.leadService.updateLeadCloseProbability(action.lead, action.probability).pipe(
          map(res => {
            if (res.result) {
              return SessionActions.UpdateLeadCloseProbabilitySuccess();
            } else {
              return SessionActions.UpdateLeadCloseProbabilityFail({ message: res.message });
            }
          })
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  updateLeadExpectedCloseDate$ = createEffect(() =>
    this.actions$.pipe(throttle(val => interval(2000)),
      ofType(SessionActions.UpdateLeadExpectedCloseDate),
      switchMap(action =>
        this.leadService.updateLeadExpectedCloseDate(action.lead, action.date).pipe(
          map(res => {
            if (res.result) {
              return SessionActions.UpdateLeadExpectedCloseDateSuccess();
            } else {
              return SessionActions.UpdateLeadExpectedCloseDateFail({ message: res.message });
            }
          })
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  lazyLoadChatMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.lazyLoadChatMessages),
      switchMap(action =>
        this.chatService.getChatMessages(action.chat).pipe(
          map(messages => {
            const messageList = new Array<ChatMessage>();

            // tslint:disable-next-line: forin
            for (const mkey in messages.list) {
              messageList.push(new ChatMessage(messages.list[mkey]));
            }
            return SessionActions.lazyLoadChatMessagesSuccess({ chat: action.chat, messages: messageList });
          }),
          catchError(error => of(SessionActions.lazyLoadChatMessagesFail({ message: error })))
        )
      )
    );
  },
    { useEffectsErrorHandler: true }
  );

  lazyLoadConversation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.lazyLoadConversation),
      switchMap(action =>
        this.leadService.getConversation(action.lead).pipe(
          map(res => {
            return new Conversation(res.data);
          }),
          mergeMap(convo =>
            this.conversationsService.getConversationHistory(convo).pipe(first(),
              map(histlist => {
                convo.history = new Array<History>();
                // tslint:disable-next-line:forin
                for (const mkey in histlist.list) {
                  convo.history.push(new History(histlist.list[mkey]));
                }
                return convo;
              })
            )
          ),
          mergeMap(convo =>
            this.messageService.getMessages(convo).pipe(first(),
              map(messagelist => {
                convo.messages = new Array<Message>();
                // tslint:disable-next-line:forin
                for (const mkey in messagelist.list) {
                  convo.messages.push(new Message(messagelist.list[mkey]));
                }
                return SessionActions.lazyLoadConversationSuccess({ lead: action.lead, convo });
              })
            )
          ),
          catchError(error => of(SessionActions.lazyLoadConversationFail({ message: error })))
        )
      ),
      catchError(error => of(SessionActions.lazyLoadConversationFail({ message: error })))
    );
  },
    { useEffectsErrorHandler: true }
  );

  lazyLoadLeadDetail$ = createEffect(() => {
    let phone: ContactNumber;
    let email: ContactEmail;
    let address: ContactAddress;
    return this.actions$.pipe(
      ofType(SessionActions.LazyLoadLeadDetail),
      switchMap(action =>
        this.leadService.getPrimaryPhone(action.lead).pipe(
          map(res => {
            phone = new ContactNumber(res.data);
            return action.lead;
          }),
          mergeMap(lead =>
            this.leadService.getPrimaryEmail(lead).pipe(
              map(res => {
                email = new ContactEmail(res.data);
                return lead;
              }
              )
            )
          ),
          mergeMap(lead =>
            this.leadService.getPrimaryAddress(lead).pipe(
              map(res => {
                address = new ContactAddress(res.data);
                return SessionActions.LazyLoadLeadDetailSuccess({ lead, phone, email, address });
              }
              )
            )
          ),
          catchError(error => of(SessionActions.LazyLoadLeadDetailFail({ message: error })))
        )
      ),
    );
  },
    { useEffectsErrorHandler: true }
  );

  callLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.CallLead),
      switchMap(action =>
        this.leadService.callLead(action.lead).pipe(
          map(res => {
            return SessionActions.CallLeadSuccess({ lead: action.lead });
          }),
          catchError(error => of(SessionActions.CallLeadFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  assignLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.AssignLead),
      switchMap(action =>
        this.leadService.assignLead(action.lead, action.user).pipe(
          map(res => {
            return SessionActions.AssignLeadSuccess({ lead: action.lead, user: action.user });
          }),
          catchError(error => of(SessionActions.AssignLeadFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  assignChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.AssignChat),
      switchMap(action =>
        this.chatService.assignChat(action.chat, action.user).pipe(
          map(res => {
            return SessionActions.AssignChatSuccess({ chat: action.chat, user: action.user });
          }),
          catchError(error => of(SessionActions.AssignChatFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  closeLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.CloseLead),
      switchMap(action =>
        this.leadService.close(action.lead, action.closeData).pipe(
          mergeMap(async (res) => SessionActions.CloseLeadSuccess({ lead: action.lead })
          ),
          catchError(error => of(SessionActions.CloseLeadFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  UpdateNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.UpdateConversationNote),
      switchMap(action =>
        this.conversationsService.updateNotes(action.lead.conversation, action.note).pipe(
          map(res => {
            return SessionActions.UpdateConversationNoteSuccess();
          }),
          catchError(error => of(SessionActions.UpdateConversationNoteFail({ message: error })))
        )
      )
    ),
    { useEffectsErrorHandler: true }
  );

  /* changeTopBar$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SessionActions.ChangeTopBar),
    exhaustMap(action =>
      this.leadService.CallLead(action.lead).pipe(
        map(res => {
          return SessionActions.CallLeadSuccess();
        }),
        catchError(error => of(SessionActions.CallLeadFail({ message: error })))
      )
    )
);
  ) */
}
