import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { RestResponse } from 'src/app/classes/rest-response/rest-response';
import * as UserActions from 'src/app/ngrx/session/session.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/ngrx';

@Injectable()
export class HttpGlobalInterceptor implements HttpInterceptor {

    private loader;

    constructor(public loadingController: LoadingController, private store: Store<AppState>) {
        this.loader = this.loadingController.create({
            spinner: 'lines',
            message: 'Waiting on Server...',
            translucent: true,
            cssClass: 'custom-class custom-loading'
        });
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /* const token = "my-token-string-from-server";

        //Authentication by setting header with token value
        if (token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': token
                }
            });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json'
                }
            });
        }

        //request = request.clone({
        //    headers: request.headers.set('Accept', 'application/json')
        //});
        //this.showLoader();
        */
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // const tmprr = new RestResponse(event.body);
                    this.store.dispatch(UserActions.ApiMessage(event.body));
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.store.dispatch(UserActions.LogoutUser());
                }
                return throwError(error);
            }));
       // return next.handle(request);
    }

    showLoader() {
        this.loader.present();
    }

    hideLoader() {
        this.loader.dismiss();
    }

}



