import { User } from './../../../classes/user/user';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestResponse } from 'src/app/classes/rest-response/rest-response';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpClient: HttpClient) { }

  public getUsers(): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(environment.apiUrl + '/rest/users', { withCredentials: true });
  }

  public registerNotificationToken(user: User, newtoken: string, oldtoken: string): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
    environment.apiUrl + '/rest/user/' + user.id + '/token',
    {'new-token': newtoken, 'old-token': oldtoken},
    { withCredentials: true }
    );
  }

  public UpdateUser(user: User, zipcode: number, experience: string, clients: string, bio: string): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/user/" + user.id + "/metadata",
      {
        zipcode,
        experience,
        clients,
        bio
      },
      { withCredentials: true }
    );
  }

  public UpdateUserPhoto(user: User, photo: File): Observable<RestResponse> {
    const formData: FormData = new FormData();
    formData.append('photo', photo, photo.name);
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/user/" + user.id + "/photo",
      formData,
      { withCredentials: true }
    );
  }
}
