import * as fromSessionSelectors from '../ngrx/session/session.selectors';
import * as UserActions from 'src/app/ngrx/session/session.actions';
import { AppState } from '../ngrx';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public store: Store<AppState>) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(fromSessionSelectors.SelectCurrentUser).pipe(map(u => {
      if (u != null) {
        return true;
      } else {
        this.store.dispatch(UserActions.LogoutUser());
        return false;
      }
    }));
  }

}
