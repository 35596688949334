import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RestResponse } from "src/app/classes/rest-response/rest-response";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Lead } from "src/app/classes/lead/lead";

@Injectable({
  providedIn: "root",
})
export class CampaignsService {
  constructor(private httpClient: HttpClient) {}

  public getCampaigns(): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(
      environment.apiUrl + "/rest/campaigns",
      { withCredentials: true }
    );
  }

  public createLead(campaign_id: string, lead: Lead): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/campaign/" + campaign_id + "/lead",
      lead,
      {
        withCredentials: true,
      }
    );
  }
}
