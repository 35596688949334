import { Component, OnInit, ViewChild } from '@angular/core';
import { IonRadioGroup, IonRadio, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/ngrx';
import * as fromSessionSelectors from 'src/app/ngrx/session/session.selectors';
import * as SessionActions from 'src/app/ngrx/session/session.actions';

@Component({
  selector: 'app-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  styleUrls: ['./calendar-filter.component.scss'],
})
export class CalendarFilterComponent implements OnInit {

  public _calendarmode: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @ViewChild('calendarRadioGroup') calendarRadioGroup: IonRadioGroup;

  constructor(
    private store: Store<AppState>,
    private popoverController: PopoverController,) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {

    this.store.select(fromSessionSelectors.SelectCalendarMode).pipe(takeUntil(this.ngUnsubscribe)).subscribe(mode => {
      this._calendarmode = mode;
    });
  }


  changeCalendarView = () => {
    if (this.calendarRadioGroup !== undefined && this.calendarRadioGroup.value !== undefined) {
      this.store.dispatch(SessionActions.UpdateCalendarMode({ mode: this.calendarRadioGroup.value }));

      this.popoverController.dismiss();
    }
  }

  backtoToday = () => {
    this.store.dispatch(SessionActions.ScheduleBackToday());
    this.popoverController.dismiss();
  }

}
