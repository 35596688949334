import { Conversation } from "./../../../classes/conversation/conversation";
import { ListData } from "../../../classes/list-data/list-data";
import { RestResponse } from "../../../classes/rest-response/rest-response";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Lead, CloseData, ContactAddress } from "src/app/classes/lead/lead";
import { User } from "src/app/classes/user/user";

@Injectable({
  providedIn: "root",
})
export class LeadsService {
  constructor(private httpClient: HttpClient) {}

  public getActiveLeads(): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(
      environment.apiUrl + "/rest/contacts",
      { withCredentials: true }
    );
  }

  public getPipelineLeads(): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(
      environment.apiUrl + "/rest/contacts",
      {
        params: {
          pipeline: "true",
        },
        withCredentials: true,
      }
    );
  }

  public getLead(id: string): Observable<Lead> {
    return this.httpClient
      .get(environment.apiUrl + "/rest/contact/" + id, {
        withCredentials: true,
      })
      .pipe(
        map((resp: RestResponse) => {
          const lead: Lead = new Lead(resp.data);
          return lead;
        })
      );
  }

  public updateLead(lead: Lead): Observable<RestResponse> {
    return this.httpClient.put<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id,
      {
        first_name: lead.first_name,
        last_name: lead.last_name,
        attention: lead.attention,
      },
      { withCredentials: true }
    );
  }

  public updateLeadSchedule(lead: Lead, date: Date): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/schedule",
      { date },
      { withCredentials: true }
    );
  }

  public updateLeadCloseProbability(
    lead: Lead,
    probability: number
  ): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/probability",
      { probability },
      { withCredentials: true }
    );
  }

  public updateLeadExpectedCloseDate(
    lead: Lead,
    date: Date
  ): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/expectedclosedate",
      { date },
      { withCredentials: true }
    );
  }

  public callLead(lead: Lead): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/call",
      "",
      { withCredentials: true }
    );
  }

  public assignLead(lead: Lead, user: User): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/assignedUser",
      { id: user.id },
      { withCredentials: true }
    );
  }

  public getConversation(lead: Lead): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/conversation",
      { withCredentials: true }
    );
  }

  public getPrimaryPhone(lead: Lead): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/primaryphone",
      { withCredentials: true }
    );
  }

  public getPrimaryEmail(lead: Lead): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/primaryemail",
      { withCredentials: true }
    );
  }

  public getPrimaryAddress(lead: Lead): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/primaryaddress",
      { withCredentials: true }
    );
  }

  public close(lead: Lead, closeData: CloseData): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/close",
      closeData,
      { withCredentials: true }
    );
  }

  public setPrimaryEmail(lead: Lead, email: string): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/primaryemail",
      { email },
      { withCredentials: true }
    );
  }

  public setPrimaryPhone(
    lead: Lead,
    phonenumber: string
  ): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/primaryphone",
      { phonenumber },
      { withCredentials: true }
    );
  }

  public setPrimaryAddress(
    lead: Lead,
    address: ContactAddress
  ): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(
      environment.apiUrl + "/rest/contact/" + lead.id + "/primaryaddress",
      { address },
      { withCredentials: true }
    );
  }
}
