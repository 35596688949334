export class ScheduleEvent {
    
    id: string;
    title: string;
    startTime: Date;
    endTime: Date;
    allDay: boolean;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }

}
