export class Huntgroup {
    id: string;
    // tslint:disable-next-line:variable-name
    date_created: string;
    name: string;
    description: string;
    // tslint:disable-next-line:variable-name
    date_edited: string;
    // tslint:disable-next-line:variable-name
    account_id: string;
    queuesid: string;
    autodial: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}
