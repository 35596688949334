import { MessageComponent } from "./message/message.component";
import { DetailPageModule } from "./../pages/leads/detail/detail.module";
import { RouterModule } from "@angular/router";
import { MainMenuComponent } from "./menu/menu.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { LaPopoverComponent } from "./lead-accordian/la-popover/la-popover.component";
import { LeadAccordianComponent } from "src/app/components/lead-accordian/lead-accordian.component";
import { MainFilterComponent } from "./main-filter/main-filter.component";
//import { IonicRatingComponentModule } from "ionic-rating-component";
import { FormsModule } from "@angular/forms";
import { CalendarFilterComponent } from "./calendar-filter/calendar-filter.component";
//import { NgCalendarModule } from "ionic6-calendar";
import { ConversationComponent } from "./conversation/conversation.component";
import { PipesModule } from "../pipes/pipes.module";

@NgModule({
    declarations: [
        MainMenuComponent,
        MessageComponent,
        LaPopoverComponent,
        LeadAccordianComponent,
        MainFilterComponent,
        CalendarFilterComponent,
        ConversationComponent,
    ],
    exports: [
        LeadAccordianComponent,
        ConversationComponent,
        MainMenuComponent,
        MessageComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        RouterModule,
        DetailPageModule,
        //IonicRatingComponentModule,
        FormsModule,
        PipesModule
    ]
})
export class ComponentsModule {}
