<ion-list lines="none">
  <ion-item-divider>
    <ion-label class="ion-no-padding" size="small">Filters</ion-label>
  </ion-item-divider>
  <ion-item>
    <ion-select #filterSelect interface="alert" style="min-width: 100%;" multiple="true" [value]="_campaignfilter"
      placeholder="Campaigns" size="small">
      <ion-select-option *ngFor="let campaign of _campaigns" value="{{campaign.id}}">
        {{campaign.name}}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label class="ion-no-padding" size="small">Need Attention</ion-label>
    <ion-toggle [(ngModel)]="_needsAttention">
    </ion-toggle>
  </ion-item>
  <ion-item *ngIf="!hideUncliamed()">
    <ion-label class="ion-no-padding" size="small">Unclaimed</ion-label>
    <ion-toggle [(ngModel)]="_unclaimed">
    </ion-toggle>
  </ion-item>
  <ion-item>
    <ion-label class="ion-no-padding" size="small">Search</ion-label>
    <ion-toggle [(ngModel)]="_searchBarVisable">
    </ion-toggle>
  </ion-item>
</ion-list>
<ion-item-divider></ion-item-divider>
<ion-button expand="block" (click)="applyFilter()" fill="clear" color="success">Apply</ion-button>
<ion-button expand="block" (click)="clearFilter()" fill="clear" color="warning">Clear All</ion-button>