import { Store, select } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/ngrx';
import { User } from 'src/app/classes/user/user';
import * as fromSessionSelectors from 'src/app/ngrx/session/session.selectors';
import * as SessionActions from 'src/app/ngrx/session/session.actions';
import * as SettingsActions from 'src/app/ngrx/settings/settings.actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'main-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnDestroy {

  public user: User;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public leadcount = 0;
  public pipelinecount = 0;
  public admin = 0;

  constructor(private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    this.store.select(fromSessionSelectors.SelectCurrentUser).pipe(takeUntil(this.ngUnsubscribe)).subscribe(u => this.user = u);
    this.store.select(fromSessionSelectors.SelectCurrentStats).pipe(takeUntil(this.ngUnsubscribe)).subscribe(s => {
      this.leadcount = s.active;
      this.pipelinecount = s.pipeline;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public logout() {
    this.store.dispatch(SessionActions.LogoutUser());
  }

  public onDarkModeChange() {
    this.store.dispatch(SettingsActions.ToggleDarkmode());
  }

}
