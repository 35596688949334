import { UsersService } from './data/users/users.service';
import { Injectable } from '@angular/core';
//import { Messaging } from '@angular/fire/messaging';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private userService: UsersService, 
    //private messaging: AngularFireMessaging 
    ) { }

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      navigator.serviceWorker.ready.then((registration) => {
        /* 
        // Don't crash an error if messaging not supported
        if (!this.messaging. isSupported()) {
          resolve();
          return;
        }

        const messaging = firebase.messaging();

        // Register the Service Worker
        messaging.useServiceWorker(registration);

        // Initialize your VAPI key
        messaging.usePublicVapidKey(
          environment.firebase.vapidKey
        );

        // Optional and not covered in the article
        // Listen to messages when your app is in the foreground
        messaging.onMessage((payload) => {
          // Create Toast alerting user?
          console.log(payload);
        });

        // Optional and not covered in the article
        // Handle token refresh
        messaging.onTokenRefresh(() => {
          messaging.getToken().then(
            (refreshedToken: string) => {
              console.log(refreshedToken);
            }).catch((err) => {
              console.error(err);
            });
        }); */

        resolve();
      }, (err) => {
        reject(err);
      });
    });
  }

  requestPermission(): Promise<string> {
    return new Promise<string>(async (resolve) => {
       let token = '';
      /*if (!Notification) {
        resolve(token);
        return;
      }
      if (!firebase.messaging.isSupported()) {
        resolve(token);
        return;
      }
      try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();

        token = await messaging.getToken();

        console.log('User notifications token:', token);
      } catch (err) {
        // No notifications granted
      } */

      resolve(token);
      return;
    });
  }

}
