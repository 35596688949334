import { createReducer, on, Action, State } from '@ngrx/store';
import * as fromActions from './settings.actions';

export interface SettingsState {
    darkmode: boolean;
}

export const initialState: SettingsState = {
    darkmode: false
};

export const SettingsReducer = createReducer(
    initialState,
    on(fromActions.ToggleDarkmode, state => ({
        ...state,
        darkmode: !!state.darkmode
    }))
);

export function reducer(state: SettingsState | undefined, action: Action) {
    return SettingsReducer(state, action);
}
