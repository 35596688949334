import { User } from "./classes/user/user";
import { Pagedata } from "src/app/classes/pagedata/pagedata";
import { NotificationsService } from "./services/notifications.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AlertController, Platform, PopoverController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { AppState } from "./ngrx";
import * as fromSessionSelectors from "src/app/ngrx/session/session.selectors";
import * as SessionActions from "src/app/ngrx/session/session.actions";
import { catchError, takeUntil } from "rxjs/operators";
import { from, Subject } from "rxjs";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { ofType, Actions } from "@ngrx/effects";
import { MainFilterComponent } from "./components/main-filter/main-filter.component";
import { NavigationExtras, Router } from "@angular/router";
import { CalendarFilterComponent } from "./components/calendar-filter/calendar-filter.component";
import { SwUpdate } from "@angular/service-worker";
import { WsMessagingService } from "./services/ws-messaging.service";
import { ChatMessage } from "./classes/chat/chat-message";
import { Message } from "./classes/message/message";
import { MainMenuComponent } from "./components/menu/menu.component";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:variable-name
  public _pagedata: Pagedata;
  public user: User;
  public attPipe: number;
  public attLead: number;
  public attCal: number;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  lastPing?: Date = null;

  constructor(
    private platform: Platform,
    private notificationsService: NotificationsService,
    private store: Store<AppState>,
    private popoverController: PopoverController,
    private idle: Idle,
    private keepalive: Keepalive,
    private updates$: Actions,
    private router: Router,
    private updates: SwUpdate,
    private alertController: AlertController,
    private wsMessagingService: WsMessagingService
  ) {
    this.initializeApp();

    // update application if new version is available.
    updates.versionUpdates.subscribe(async (event) => {
      if (event.type == "VERSION_READY") {
        const alert = await this.alertController.create({
          header: "Update Available",
          message: "Get the new Jabrly app?",
          buttons: [
            {
              text: "Later",
              role: "cancel",
              cssClass: "medium",
              handler: (blah) => {
                console.log("Ask again later");
              },
            },
            {
              text: "Update",
              handler: () => {
                updates.activateUpdate().then(() => document.location.reload());
              },
            },
          ],
        });

        updates.checkForUpdate();

        await alert.present();
      }
    });
  }

  initializeApp() {
    this.store
      .select(fromSessionSelectors.SelectTopBarData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((p) => (this._pagedata = p));

    this.store
      .select(fromSessionSelectors.SelectCurrentUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((u) => (this.user = u));

    this.store
      .select(fromSessionSelectors.SelectAttentionLeads)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((l) => (this.attLead = l.length));

    this.store
      .select(fromSessionSelectors.SelectAttentionPipeline)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((p) => (this.attPipe = p.length));

    this.store
      .select(fromSessionSelectors.SelectAttentionCalendar)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((c) => (this.attCal = c.length));

    this.platform.ready().then(async () => {
      await this.notificationsService.init();
    });
    // sets an idle timeout of 10 minutes.
    this.idle.setIdle(600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(15);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // this.idle.onIdleEnd.subscribe(() => console.log('No longer idle.'));
    // this.idle.onIdleStart.subscribe(() => console.log('You\'ve gone idle!'));
    // this.idle.onTimeoutWarning.subscribe((countdown) => console.log('You will time out in ' + countdown + ' seconds!'));

    this.idle.onTimeout.subscribe(() => {
      console.log("Timed out!");
      this.store.dispatch(SessionActions.LogoutUser());
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(180);

    this.keepalive.onPing.subscribe(() =>
      this.store.dispatch(SessionActions.AuthPing())
    );

    this.updates$
      .pipe(
        ofType(SessionActions.AuthUserSuccess),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((props) => {
        this.store.dispatch(
          SessionActions.UpdateCampaignFilter({ filter: [] })
        );
        this.idle.watch();

        this.wsMessagingService.connect();

        this.wsMessagingService.messages$
          .pipe(
            takeUntil(this.ngUnsubscribe),
            catchError((error) => {
              throw error;
            })
          )
          .subscribe({
            next: (msg) => {
              if (msg.chat_id !== undefined) {
                const message = new ChatMessage(msg as object);
                this.store.dispatch(SessionActions.NewChatMessage({ message }));
                if (message.message_direction === "IN") {
                  const audio = new Audio(
                    "./../../../assets/sounds/juntos-607.mp3"
                  );
                  audio.play();
                }
              } else {
                const message = new Message(msg as object);
                this.store.dispatch(SessionActions.NewTextMessage({ message }));
                if (message.message_direction === "IN") {
                  const audio = new Audio(
                    "./../../../assets/sounds/juntos-607.mp3"
                  );
                  audio.play();
                }
              }
            },
            error: (error) =>
              console.log("[Conversation component] Error:", error),
            complete: () =>
              console.log("[Conversation component] Connection Closed"),
          });
      });
    this.updates$
      .pipe(ofType(SessionActions.LogoutUser), takeUntil(this.ngUnsubscribe))
      .subscribe((props) => {
        this.wsMessagingService.close();
        this.idle.stop();
      });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async showfilterpopover(ev) {
    const popover = await this.popoverController.create({
      component: MainFilterComponent,
      event: ev,
      translucent: true,
    });
    // currentPopover = popover;
    return popover.present();
  }

  async showcalendarpopover(ev) {
    const popover = await this.popoverController.create({
      component: CalendarFilterComponent,
      event: ev,
      translucent: true,
    });
    // currentPopover = popover;
    return popover.present();
  }

  async linkToAttention(ev) {
    const ne: NavigationExtras = {
      queryParams: {
        attention: 1,
      },
    };

    if (this.attPipe > 0) {
      this.router.navigate(["/app/pipeline"], ne);
    } else if (this.attLead > 0) {
      this.router.navigate(["/app/leads"], ne);
    } else if (this.attCal > 0) {
      this.router.navigate(["/app/calendar"]);
    }
  }

  hidefilter = () => {
    return this._pagedata?.displayfilter;
  };

  hidecalendarmode = () => {
    return this._pagedata?.displaycalendar;
  };

  hideAttention = () => {
    return this.attLead + this.attPipe !== 0;
  };
}
