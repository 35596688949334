import { environment } from 'src/environments/environment';
import { RestResponse } from '../classes/rest-response/rest-response';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserCredentials } from '../classes/user/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient) {
  }

  login(value: UserCredentials): Observable<RestResponse> {
    return this.httpClient.post<RestResponse>(environment.apiUrl + '/auth', value, { withCredentials: true });
  }

  logout(): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(environment.apiUrl + '/auth/logout');
  }

  ping(): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(environment.apiUrl + '/rest/ping', { withCredentials: true });
  }

}
