import * as fromSessionSelectors from '../ngrx/session/session.selectors';
import * as UserActions from 'src/app/ngrx/session/session.actions';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../ngrx';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(public store: Store<AppState>) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.store.select(fromSessionSelectors.SelectCurrentUser).pipe(map(u => {
        if (u != null) {
          return Number(u.user_level) === 0;
        } else {
          this.store.dispatch(UserActions.LogoutUser());
          return false;
        }
      }));
  }
}
